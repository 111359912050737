import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { useAppSelector } from './state/hooks';


const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications

const Home = Loader(
  lazy(() => import('src/content/applications/Home'))
);
const DebtorLists = Loader(
  lazy(() => import('src/content/applications/Debtor/lists'))
);
const DebtorDetails = Loader(
  lazy(() => import('src/content/applications/Debtor/details'))
);
const Debtor = Loader(
  lazy(() => import('src/content/applications/Debtor/lists'))
);
const Deals = Loader(
  lazy(() => import('src/content/applications/Deals'))
);

const CollectionHistory = Loader(
  lazy(() => import('src/content/applications/CollectionHistory'))
);

const CollectionTargets = Loader(
  lazy(() => import('src/content/applications/CollectionTarget'))
);

const CollectionDetail = Loader(
  lazy(() => import('src/content/applications/CollectionTarget/detailData'))
);

const CollectionWaitingExec = Loader(
  lazy(() => import('src/content/applications/CollectionTarget/waitingExec'))
);

const CollectionWaitingResult = Loader(
  lazy(() => import('src/content/applications/CollectionTarget/waitingResult'))
);

const Template = Loader(
  lazy(() => import('src/content/applications/Template'))
);

const DefaultLists = Loader(
  lazy(() => import('src/content/applications/Default/lists'))
);

const DefaultDetails = Loader(
  lazy(() => import('src/content/applications/Default/details'))
);

const Exclude = Loader(
  lazy(() => import('src/content/applications/Default/exclude'))
);

const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

const Offer = Loader(
  lazy(() => import('src/content/applications/Offer'))
);


const Document = Loader(
  lazy(() => import('src/content/applications/Document'))
);

// Specials

const SystemSettings = Loader(
  lazy(() => import('src/content/applications/SystemSettings'))
);
const Totalling = Loader(
  lazy(() => import('src/content/applications/Totalling'))
);


// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const Login = Loader(
  lazy(() => import('src/content/pages/Status/Login'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/home" replace />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'home',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Home />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Crypto />
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="debtor" replace />
      },
      {
        path: 'debtor',
        element: <Debtor />
      },
      {
        path: 'debtor',
        children: [
          {
            path: '',
            element: <Navigate to="lists" replace />
          },
          {
            path: 'lists',
            element: <DebtorLists />
          },
          {
            path: 'details/:id',
            element: <DebtorDetails />
          }
        ]
      },
      {
        path: 'offer',
        element: <Offer />
      },
      {
        path: 'offer',
        children: [
          {
            path: '',
            element: <Offer />
          },
        ]
      },
      {
        path: 'document',
        element: <Document />
      },
      {
        path: 'document',
        children: [
          {
            path: '',
            element: <Document />
          },
        ]
      },
      {
        path: 'deal',
        children: [
          {
            path: '',
            element: <Deals />
          }
        ]
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },
  {
    path: 'delay',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="collection" replace />
      },
      {
        path: 'collectionHistory',
        children: [
          {
            path: '',
            element: <CollectionHistory />
          },
        ]
      },
      {
        path: 'collectionTargets',
        children: [
          {
            path: '',
            element: <CollectionTargets />
          },
          {
            path: 'detail',
            element: <CollectionDetail />
          },
          {
            path: 'collectionWaitingExec',
            element: <CollectionWaitingExec />
          },
          {
            path: 'collectionWaitingResult',
            element: <CollectionWaitingResult />
          }
        ]
      },
      {
        path: 'template',
        children: [
          {
            path: '',
            element: <Template />
          },
        ]
      },
      {
        path: 'default',
        children: [
          {
            path: '',
            element: <Navigate to="lists" replace />
          },
          {
            path: 'lists',
            element: <DefaultLists />
          },
          {
            path: 'details',
            element: <DefaultDetails />
          },
          {
            path: 'exclude',
            element: <Exclude />
          }
        ]
      },
    ]
  },
  {
    path: 'specials',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <SystemSettings />
      },
      {
        path: 'systemsettings',
        element: <SystemSettings />
      },
      {
        path: 'totalling',
        element: <Totalling />
      }
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
